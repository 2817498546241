<template>
  <div class="row">
    <div class="column" > 
      <canvas id="pieChart2" width="400" height="400"></canvas>
      
    </div>
 
  </div>
  
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  props: [
  
  ],
  data() {
    return {
      noOfFintech:'',
      label: [],
      dataValue: [],
      customersGaming:[],
      labelObj:[]
    };
  },
  computed: {
   
  },
  mounted() {
    this.searchByGaming();

    
  },
  methods: {
    searchByGaming(){
             const params = {
		          signupHost: 'gaming.ceza.org',
                  itemsPerPage: '100',
                  limit: '50000',
                  offset: '0',
		        }

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/customerSearchBySignupHost/',
                    params
                    };

              this.axios(options)
              .then((response) => { 

                this.customersGaming = response.data
                
                  let statusArr = [];

                  response.data.forEach((el, i) => {
                      statusArr.push(el.verificationStatus);
                  })


                  let countMap = statusArr.reduce((el, i) => {
                      el[i] = (el[i] || 0) + 1;
                      return el;
                  }, {});

                  let countArray = Object.keys(countMap).map(key => ({ value: key, count: countMap[key] }));


                  for(let i = 0;i < countArray.length ; i++) {

                      this.label.push(countArray[i].value);
                      this.dataValue.push(countArray[i].count);

                  }


                
                  const data = {
                   
                    
                  labels: 
                    this.label,
                    datasets: [{
                      label: 'My First Dataset',
                      data: this.dataValue,
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)'
                      ],
                      hoverOffset: 4
                    }]
                    };
                    const config = {
                      type: 'doughnut',
                      data,
                    };
                    const pieChart2 =  new Chart(
                      document.getElementById("pieChart2"),
                      config
                    ) 
                    
                    
                   

     
                });
    },
  
  }
}
</script>

<style lang="scss" scoped>
.row 
h5 {
  margin: 0 auto !important;
  text-align: center !important;
  display: flex;
  justify-content: center !important;

}

</style>
